var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page more-depth"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"page-header__title"},[_vm._v(" "+_vm._s(_vm._f("capitalizeFirstLetter")(_vm.$__t("약관동의")))+" ")])]),_c('div',{staticClass:"page-body"},[_c('ui-form',{ref:"form",attrs:{"formData":_vm.formData,"ignoreSubmit":true},on:{"submit":_vm.submitForm}},[_c('ui-form-list',[_c('ui-form-list-item',{staticClass:"button-area"},[_c('button',{directives:[{name:"button",rawName:"v-button"}],staticClass:"ui-check-button",class:{ activated: _vm.formActivated },attrs:{"type":"button"},on:{"click":_vm.checkAll}},[_c('img',{staticClass:"ui-check-button__image",attrs:{"src":_vm.formActivated
                    ? '/img/icon/check-skyblue.svg'
                    : '/img/icon/check-gray.svg'}}),_c('span',{staticClass:"ui-check-button__text"},[_vm._v(" "+_vm._s(_vm.$__t("약관에 모두 동의"))+" ")])])]),_c('ui-form-list-item',[_c('v2-check',{attrs:{"label":_vm.formData.term_1.label,"router-link":_vm.formData.term_1.router_link},model:{value:(_vm.formData.term_1.value),callback:function ($$v) {_vm.$set(_vm.formData.term_1, "value", $$v)},expression:"formData.term_1.value"}})],1),_c('ui-form-list-item',[_c('v2-check',{attrs:{"label":_vm.formData.term_2.label,"router-link":_vm.formData.term_2.router_link},model:{value:(_vm.formData.term_2.value),callback:function ($$v) {_vm.$set(_vm.formData.term_2, "value", $$v)},expression:"formData.term_2.value"}})],1)],1)],1),_c('div',{staticClass:"noticeForHost"},[_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$__t(
              'If you are an existing host, please login right away without proceeding with the membership registration!'
            )
          )}}),_vm._v(" 📌 "+_vm._s(_vm.$__t( "로그인 후 호스트 페이지 접근이 불가할 경우 고객센터로 연락 주시면 빠르게 해결해 드릴게요" ))+" 🙂 ")])],1),_c('div',{staticClass:"page-footer"},[_c('button',{directives:[{name:"button",rawName:"v-button"}],staticClass:"ui-submit-button",class:{ activated: _vm.formActivated },attrs:{"type":"button","disabled":!_vm.formActivated},on:{"click":function($event){return _vm.$refs.form.submit()}}},[_c('span',{staticClass:"ui-submit-button__text"},[_vm._v(" "+_vm._s(_vm.$__t("{terms-of-use-form}.agree"))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }