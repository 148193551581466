<template>
  <div class="page more-depth">
    <div class="container">
      <div class="page-header">
        <div class="page-header__title">
          {{ $__t("약관동의") | capitalizeFirstLetter }}
        </div>
      </div>
      <div class="page-body">
        <ui-form
          ref="form"
          :formData="formData"
          @submit="submitForm"
          :ignoreSubmit="true"
        >
          <ui-form-list>
            <!--약관에 모두동의 버튼-->
            <ui-form-list-item class="button-area">
              <button
                type="button"
                @click="checkAll"
                v-button
                class="ui-check-button"
                :class="{ activated: formActivated }"
              >
                <img
                  :src="
                    formActivated
                      ? '/img/icon/check-skyblue.svg'
                      : '/img/icon/check-gray.svg'
                  "
                  class="ui-check-button__image"
                />
                <span class="ui-check-button__text">
                  {{ $__t("약관에 모두 동의") }}
                </span>
              </button>
            </ui-form-list-item>
            <!--이용약관동의 2개-->
            <ui-form-list-item>
              <v2-check
                v-model="formData.term_1.value"
                :label="formData.term_1.label"
                :router-link="formData.term_1.router_link"
              />
            </ui-form-list-item>
            <ui-form-list-item>
              <v2-check
                v-model="formData.term_2.value"
                :label="formData.term_2.label"
                :router-link="formData.term_2.router_link"
              />
            </ui-form-list-item>
          </ui-form-list>
        </ui-form>
        <div class="noticeForHost">
          <div
            v-html="
              $__t(
                'If you are an existing host, please login right away without proceeding with the membership registration!'
              )
            "
          ></div>
          📌
          {{
            $__t(
              "로그인 후 호스트 페이지 접근이 불가할 경우 고객센터로 연락 주시면 빠르게 해결해 드릴게요"
            )
          }}
          🙂
        </div>
      </div>

      <div class="page-footer">
        <button
          type="button"
          v-button
          @click="$refs.form.submit()"
          class="ui-submit-button"
          :disabled="!formActivated"
          :class="{ activated: formActivated }"
        >
          <span class="ui-submit-button__text">
            {{ $__t("{terms-of-use-form}.agree") }}
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        term_1: {
          label: `[${this.$__t("{terms-of-use-form}.necessary")}] ${this.$__t(
            "서비스 이용약관 동의"
          )}`,
          value: false,
          validation: { type: "required" },
          router_link: {
            to: { name: "CsTermsOfUse", params: { headerType: "more-depth" } },
            text: this.$__t("보기"),
          },
        },
        term_2: {
          label: `[${this.$__t("{terms-of-use-form}.necessary")}] ${this.$__t(
            "개인정보 수집 및 이용동의"
          )}`,
          value: false,
          validation: { type: "required" },
          router_link: {
            to: { name: "CsPrivacyPolicy", params: { headerType: "more-depth" } },
            text: this.$__t("보기"),
          },
        },
      },
    };
  },

  computed: {
    formActivated() {
      return Object.keys(this.formData).every((o) =>
        this.formData[o].validation.type === "required" ? !!this.formData[o].value : true
      );
    },
  },

  methods: {
    submitForm(values) {
      // return this.$router.push({ name: "AuthRegisterEmail" });
      return this.$router.push({ name: "AuthRegisterPhone", query: this.$route.query });
    },

    checkAll() {
      return Object.keys(this.formData).forEach((o) => (this.formData[o].value = true));
    },
  },
};
</script>

<style lang="scss" scoped>
.noticeForHost::v-deep {
  margin-top: unit(20);
  padding: unit(10);
  font-size: unit(15);
  line-height: unit(23);
  border: 1px solid #c8c8c8;

  .focus {
    font-weight: 700;
  }
}
</style>
